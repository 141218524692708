@media screen and (min-width: 1800px) {
  .book-your-product-bg{
    width: 50%;
    height: 538px;
  }
  .book-pod-img{
    left: -50px;
    top: -140px;
    width: auto;
  }
  .about-vapebae{
    margin-top: 140px;
  }
}  
@media screen and (max-width: 1399.5px) {  
  .product-slider {
    max-width: 370px;
    width: 100%;
  }
  .slider-card {
    height: 370px;
  }
  .p-xlg{font-size:32px}
  #progressbar li:after {
    width: 286px;
    left: -171px;
  }
  header .navbar-nav a{font-size: 15px;}
  .header-top {
    font-size: 13px;
  }
  h1{
    font-size: 104px;
    line-height: 77px;
  }
  h1.sm-banner-heading {
    font-size: 95px;
    line-height: 70px;
  }
  h2{font-size: 70px;}
  .banner{
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .special-offer-img-1 {
    width: 100px !important;
  }
  .special-offer-img-2 {
    width: 190px;
    left: -30px;
  }
  
  .special-offer-img-3 {
    width: 170px;
    left: -80px;
  }
  
  .special-offer-img-4 {
    left: -100px;
    width: 130px;
  }
  .special-offer-img-5 {
    width: 160px;
  }
  .special-offer-img-6{
    width: 390px;
  }
  .new-arrival-text::before {
    height: 85%;
  }
  .arrival-img {
    height: 232px;
  }
  .arrival-carousel .owl-nav button{
    width: 30px !important;
    height: 30px !important;
  }
  .arrival-carousel .owl-nav button.owl-prev{
    left: -30px;
  }
  .arrival-carousel .owl-nav button.owl-next{
    right: -30px;
  }
  .book-your-products{
    min-height: 350px;
  }
  .book-pod-img{
    left: -50px;
    top: -50px;
    width: 250px;
  }
  .book-your-product-bg{
    height: 350px;
  }
  .feature-card-inner{
    max-width: 200px;
  }
  .feature-card-img{
    bottom: -40px;
  }
  .feature-card-img-1{
    width: 140px !important;
  }
  .feature-card-img-2{
    width: 250px !important;
  }
  .feature-card-img-3{
    width: 120px !important;
  }
}
@media screen and (max-width: 1199.5px) {
  .product-thumbnail{
    max-width: 100%;
  }
  .product-slider{
    max-width: 100%;
  }
  .mega-menu p{
    font-size: 14px;
  }
  .arrival-carousel .owl-nav button.owl-prev{
    left: -20px;
  }
  .arrival-carousel .owl-nav button.owl-next{
    right: -20px;
  }
  h2{font-size: 60px;}
  h3{font-size: 35px;}
  h4{font-size: 25px;}
  h5{font-size: 22px;}
  h6{font-size: 20px;}
  #progressbar li:after {
    width: 247px;
    left: -131px;
  }
  header .navbar-nav a{font-size: 14px;}
  .header-top {
    font-size: 11px;
  }
  h1 {
    font-size: 89px;
    line-height: 65px;
    letter-spacing: -3px;
  }
  h1.sm-banner-heading {
    font-size: 78px;
    line-height: 58px;
  }
  .special-offer-img-1 {
    width: 90px !important;
  }
  .special-offer-img-2 {
    width: 170px;
    left: -20px;
  }
  
  .special-offer-img-3 {
    width: 150px;
    left: -60px;
  }
  
  .special-offer-img-4 {
    left: -80px;
    width: 110px;
  }
  .special-offer-img-5 {
    width: 140px;
    left: -150px;
  }
  .special-offer-img-6{
    width: 340px;
    left: -120px;
  }
  .new-arrival-text::before {
    height: 78%;
  }
  .book-your-products{
    min-height: 300px;
  }
  .book-pod-img{
    left: -50px;
    top: -25px;
    width: 200px;
  }
  .book-your-product-bg{
    height: 300px;
  }
  .feature-card-img-2 {
    right: -25px;
  }
}

@media screen and (max-width: 991.5px) {
  .modal-check-icon{
    width: 100px;
  }
  .header-loggedin .navbar-nav{padding:10px 20px;position:relative;background:#fff;box-shadow:0 3px 10px rgb(0 0 0 / 16%);}
  .navbar-nav{padding:10px 20px;position:relative;background:#fff;box-shadow:0 3px 10px rgb(0 0 0 / 16%);}
  .header-top {
    font-size: 13px;
  }
  h1 {
    font-size: 76px;
    line-height: 55px;
  }
  h2{font-size: 52px;}
  h3{font-size: 32px;}
  h4{font-size: 24px;}
  h5{font-size: 21px;}
  .border-lg-left{border: 0;}
  br{display: none;}
  .cart-table br{display: block;}
  .banner h1 br{display: block;}
  #progressbar li:after {
    width: 271px;
    left: -156px;
  }
  .header-dropdown .dropdown-menu{
    position: absolute;
  }
  h1 span.banner-text-left {
    margin-left: -14px;
  }
  .banner {
    padding-top: 150px;
    text-align: center;
    padding-bottom: 50px;
  }
  .banner-img{
    max-width: 450px;
    margin: 0 auto;
  }
  .banner .big-btn{
    margin: 0 auto;
  }
  .banner-carousel .owl-dots{
    position: static;
    transform: none;
  }
  .banner-carousel{
    margin-bottom: 0;
  }
  .special-offers{
    padding: 60px 0;
  }
  .our-store{
    padding: 40px 0 60px 0;
  }
  .special-offer-imgs {
    bottom: -15px;
  }
  .special-offer-img-wrapper {
    bottom: -20px;
  }
  .special-offer-img-1 {
    width: 70px !important;
  }
  .special-offer-img-2 {
    width: 135px;
    left: -20px;
  }
  
  .special-offer-img-3 {
    width: 118px;
    left: -49px;
  }
  
  .special-offer-img-4 {
    left: -65px;
    width: 90px;
  }
  .special-offer-img-5 {
    width: 100px;
    left: -150px;
  }
  .special-offer-img-6{
    width: 270px;
    left: -98px;
  }
  .new-arrival-text::before {
    height: 63%;
  }
  .arrival-cannabis{
    width: 150px;
  }
  .book-pod-img{
    position: static;
  }
  .book-your-products{
    text-align: center;
    padding: 40px 0;
  }
  .book-your-product-bg{display: none;}
  .about-vapebae{
    background-position: left;
  }
  .about-vapebae-img{
    position: static;
    margin-top: 50px;
    width: 100%;
  }
  .feature-card-img{
    bottom: -20px;
  }
  .feature-card-img-1{
    width: 120px !important;
  }
  .feature-card-img-2{
    width: 220px !important;
  }
  .feature-card-img-3{
    width: 100px !important;
  }
  .vapebae-features-inner{
    padding: 20px 0 50px 0;
  }
  .brands{
    padding-top: 50px;
  }
  .inner-banner {
    padding: 170px 0 70px 0 !important;
  }
  .droppable .mega-menu {
    display: none !important;
  }
  .droppable .mega-menu-show{
    display: block !important;
  }
}

@media screen and (max-width: 767.5px) {
  .brands{
    padding-top: 40px;
  }
  h1, h1.sm-banner-heading{font-size: 75px;letter-spacing: -2px;}
  h2{font-size: 45px;}
  h3{font-size: 27px;}
  h4{font-size: 22px;}
  h5{font-size: 20px;}
  h6{font-size: 18px;}
  .ctn-preloader .animation-preloader .txt-loading{font:bold 3em "Circular Std Book";}
  #progressbar li:after {
    width: 213px;
    left: -100px;
  }
  .special-offers{
    padding: 50px 0;
  }
  .our-store{
    padding: 40px 0 50px 0;
  }
  .special-offer-img-6{
    width: 100%;
    position: static;
  }
  .new-arrival-text {
    padding-top: 45px;
  }
  .footer-border::before {
    top: -36px;
  }
  .p-lg{font-size: 19px;}
}

@media screen and (max-width: 575.5px) {
  .special-offers{
    padding: 40px 0;
  }
  .our-store{
    padding: 40px 0;
  }
  #progressbar li:after {
    display: none;
  }
  body{font-size: 14px;}
  .ctn-preloader .animation-preloader .txt-loading{font:bold 2.2em "Circular Std Book";}
  h1{font-size: 50px;line-height: 39px;}
  h2{font-size: 35px;}
  h3{font-size: 24px;}
  h4{font-size: 20px;}
  h5{font-size: 18px;}
  h6{font-size: 17px;}
  p{font-size: 14px;}
  #progressbar li h3{
    font-size: 14px;
  }
  #progressbar li i {
    top: 64px;
  }
  #progressbar li:before {
    width: 57px;
    height: 57px;
  }
  h1.sm-banner-heading {
    font-size: 48px;
    line-height: 38px;
  }
  .special-offer-card {
    padding: 30px 20px 0 20px;
  }
  .new-arrival-text {
    padding-top: 0;
  }
  .new-arrival-text::before{
    display: none;
  }
  .arrival-cannabis{
    display: none;
  }
  .arrival-carousel .owl-nav{
    display: none !important;
  }
  .about-vapebae-right{
    display: none;
  }
  .feature-card-img{display: none;}
  .feature-card-inner{
    max-width: 100%;
  }
  .vapebae-features-inner{
    padding: 0 0 40px 0;
  }
  .inner-banner {
    padding: 150px 0 50px 0 !important;
  }
  .inner-banner-2 {
    min-height: 130px;
  }
  .p-lg{
    font-size: 17px;
  }
  #progressbar{
    padding: 20px !important;
  }
}
@media screen and (max-width: 460px) {
  #progressbar li {
    gap: 4px;
  }
  #progressbar li h3, #progressbar li .p-lg{
    font-size: 12px;
  }
  #progressbar {
    padding: 20px 10px !important;
  }
  .payment-method{
    padding: 15px;
  }
  .site-modal {
    padding: 10px !important;
  }
  .card-payment-note{
    padding: 30px 20px;
  }
}